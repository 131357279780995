import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./styles.css";

function Home() {
  const [faqOpen, setFaqOpen] = useState(null);

  const handleFaqToggle = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  const handleStartBrewingClick = () => {
    window.location.href = "/register";
  };

  return (
    <>
      <Helmet>
        <title>BrewVault - Brewing Tools and Tips</title>
        <meta
          name="description"
          content="BrewVault provides brewing tips, tools, and recipes for professional brewers and enthusiasts."
        />
        <meta
          name="keywords"
          content="beer brewing, professional brewing, home brewing, brewing tools, beer recipes"
        />
        <meta
          property="og:title"
          content="BrewVault - Brewing Tools and Tips"
        />
        <meta
          property="og:description"
          content="BrewVault provides brewing tips, tools, and recipes for brewers."
        />
        <meta property="og:image" content="link_to_image.jpg" />
        <meta property="og:url" content="https://BrewVault.net" />
      </Helmet>

      <div className="home-content">
        {/* Hero Section */}
        <div className="hero-section">
          <h2>BrewVault - Your gateway to professional brewing information</h2>
          <p>
            Explore tips, tools, and techniques for home and professional
            brewers alike.
          </p>
          <p>
            <button className="cta-button" onClick={handleStartBrewingClick}>
              Start using BrewVault today
            </button>
          </p>
        </div>

        {/* FAQ Section */}
        <section className="faq-section">
          <h3>Frequently Asked Questions</h3>
          <div className="faq-item" onClick={() => handleFaqToggle(1)}>
            <h3>- What is BrewVault? </h3>
            {faqOpen === 1 && (
              <div className="faq-answer">
                <p>
                  BrewVault is a platform designed for both home and
                  professional brewers to find tools, tips, and techniques to
                  enhance their brewing process.
                </p>
              </div>
            )}
          </div>
          <div className="faq-item" onClick={() => handleFaqToggle(2)}>
            <h3>- How do I get started with brewing?</h3>
            {faqOpen === 2 && (
              <div className="faq-answer">
                <p>
                  Getting started is easy! You can sign up, browse our
                  tutorials, and follow the brewing process step-by-step with
                  the help of our tools and recipes.
                </p>
              </div>
            )}
          </div>
          <div className="faq-item" onClick={() => handleFaqToggle(3)}>
            <h3>- Is BrewVault free to use?</h3>
            {faqOpen === 3 && (
              <div className="faq-answer">
                <p>
                  Yes! BrewVault offers free access to most of its features. We
                  do, however, offer premium services for advanced tools and
                  features.
                </p>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
