import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./styles.css";
import Header from "./Header";
import Account from "./accounts/Account";
import Login from "./accounts/Login";
import Register from "./accounts/Register";
import ForgotPassword from "./accounts/ForgotPassword";
import Home from "./Home";
import BJCPStyleSearch from "./tools/BJCPStyleSearch";
import DataFetcher from "./tools/DataFetcher";
import ColourConverter from "./tools/ColourConverter";
import PressureCalculator from "./tools/PressureCalculator";
import Checkout from "./payment/Checkout";
import MeadBackSweetening from "./tools/MeadBackSweetening";
import Footer from "./footer";

import "@fortawesome/fontawesome-free/css/all.min.css";

import { getCurrentUser } from "./services/AuthService";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  React.useEffect(() => {
    const user = getCurrentUser();
    if (user) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Router>
      <div>
        <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bjcpstylesearch" element={<BJCPStyleSearch />} />
            <Route path="/colourconverter" element={<ColourConverter />} />
            <Route
              path="/meadbacksweetening"
              element={<MeadBackSweetening />}
            />
            <Route
              path="/pressurecalculator"
              element={<PressureCalculator />}
            />
            <Route
              path="/login"
              element={<Login setIsLoggedIn={setIsLoggedIn} />}
            />
            <Route path="/register" element={<Register />} />
            <Route
              path="/account"
              element={isLoggedIn ? <Account /> : <Navigate to="/login" />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/data-fetcher"
              element={
                isLoggedIn ? <DataFetcher /> : <Navigate to="/account" />
              }
            />
            <Route
              path="/checkout"
              element={isLoggedIn ? <Checkout /> : <Navigate to="/account" />}
            />
            <Route
              path="/meadbacksweetening"
              element={<MeadBackSweetening />}
            />
          </Routes>
        </div>
      </div>

      <Footer />
    </Router>
  );
}

export default App;
